.square {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    display: inline-block;
    cursor: pointer;
}

.square.filled {
    background-color: black;
}

.grid {
    width: 370px;
    margin-bottom: 10px;
}

.gridValues {
    font-size: 1.2em;
}

table, th, td {
    border: 1px solid black;
}